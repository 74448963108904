<!--  -->
<template>
      <div class='painter flex-column'>
            <a href="javascript:void(0);" ref="download" download="picture.png" v-show="false"></a>
            <div class="LeftMask flex-align-between">
                  <div class="instrument flex-column-around" :class="{'instruments':drawerstate}">
                        <div>
                              <div class="Masklist " v-for="(item,index) in instrument" :key="index">
                                    <div class="Maskson ">
                                          <div class="standard flex-column-center"
                                                @click="item.operation?switchover(index) : handleChangeToolType(index)">
                                                <img :src="item.img">
                                                <span>{{item.name}}</span>
                                          </div>
                                          <div class="unfold" :class="index === MaskIndex?item.classType:'default'"
                                                v-if="index !=0">
                                                <div class="unfoldbox flex-layout">
                                                      <div class="flex-column-center"
                                                            v-for="(itemSon,indexs) in item.sublevel" :key="indexs"
                                                            @click="itemSonck(item.name,itemSon.sundry)">
                                                            <div class="flex-align-center">
                                                                  <img :src="itemSon.img" alt="">
                                                            </div>
                                                            <span>
                                                                  {{itemSon.name}}</span>
                                                      </div>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>
                  </div>
                  <div class="painterbox">
                        <div class="board" v-if="addType">
                              <canvas id="ctx_front" ref="ctx_front" :style="'cursor:' + cursor"></canvas>
                              <canvas id="ctx_base" ref="ctx_base" :style="'cursor:' + cursor"></canvas>
                              <canvas id="ctx_back" ref="ctx_back" :style="'cursor:' + cursor"></canvas>
                              <input name="text" id="text" @blur="handleTextBlur" @keyup.enter="handleTextBlur"
                                    v-model="text" autofocus autocomplete="off" :style="
              'font-size:' + (this.slide) + 'px;color:' + defaultColor
            " />
                        </div>
                        <div class="Hanzi flex-align-wrap" v-show="!showType" ref="view2">
                              <canvas class="cs" width="800" height="800" v-for="(item,index) in 4"
                                    :key="index"></canvas>
                        </div>
                        <div class="view flex-align-center" v-show="showType" ref="view1">
                             <div class="viewImg">
                                   <img src="../../assets/zi.png" alt="">
                                   <img src="../../assets/chi.png" alt="">
                             </div>
                        </div>
                  </div>
            </div>
            <div class="bottomMask flex-align-around">
                  <el-button type="primary" @click="screen">全屏</el-button>
                  <el-button type="primary" @click="add1">生成画板</el-button>
                  <el-button type="primary" @click="centerDialogVisible = true">计时器</el-button>
                  <el-button type="primary" @click="goback">退出</el-button>

            </div>

            <!-- 弹窗---------------------------------------------------------------- -->
            <el-dialog title="计时器" :visible.sync="centerDialogVisible" width="30%" center>
                  <div class="switchbox">
                        <el-switch v-model="switchStatus" active-text="倒计时" inactive-text="正计时" @change="switchChange">
                        </el-switch>
                  </div>
                  <TimeWidget ref="Widget" @childFn="parentFn"></TimeWidget>
                  <div class="controlBtn">
                        <div class="flex-align-around">
                              <el-button size="small" @click="transmit('30','s')">30s</el-button>
                              <el-button size="small" @click="transmit('1','m')">1分钟</el-button>
                              <el-button size="small" @click="transmit('2','m')">2分钟</el-button>
                              <el-button size="small" @click="transmit('3','m')">3分钟</el-button>
                        </div>
                        <div class="flex-align-around">
                              <el-button size="small" @click="transmit('5','m')">5分钟</el-button>
                              <el-button size="small" @click="transmit('10','m')">10分钟</el-button>
                              <el-button size="small" @click="transmit('15','m')">15分钟</el-button>
                              <el-popover placement="left" width="160" v-model="visible">
                                    <div class="inputBox">
                                          <el-row>
                                                <el-input v-model="secondValue" placeholder="秒" @focus='secondFocus'>
                                                </el-input>
                                                <el-input v-model="minuteValue" placeholder="分钟" @focus='minuteFocus'>
                                                </el-input>
                                          </el-row>
                                    </div>
                                    <div style="text-align: right; margin: 0">
                                          <el-button size="mini" type="text" @click="visible = false">取消</el-button>
                                          <el-button type="primary" size="mini" @click="customization">确定</el-button>
                                    </div>
                                    <el-button size="small" slot="reference">自定义</el-button>
                              </el-popover>

                        </div>
                        <div class="statrBtn flex-align-center">
                              <!-- <el-tooltip class="item" effect="light" content="请选择时间" placement="top" v-model='tooltip'> -->
                              <el-button type="primary" :icon="iconType?'el-icon-video-pause':'el-icon-video-play'"
                                    @click="startTime"></el-button>
                              <!-- </el-tooltip> -->

                        </div>
                  </div>
            </el-dialog>
            <!-- 弹窗---------------------------------------------------------------- -->
      </div>
</template>

<script>
      import html2canvas from "html2canvas";
      import cursors from "./js/cursor";
      import bg from "./js/img";
      import TimeWidget from './common/timeWidget.vue';
      export default {
            components: {
                  TimeWidget,
            },
            data() {

                  return {
                        drawerstate: false,
                        MaskIndex: '',
                        dom: '',
                        can_show2: true,
                        can_show3: false,
                        num: 1,
                        time1: '',
                        instrument: [
                              {
                                    name: '关闭',
                                    img: require('../../assets/assistant/close.png'),
                                    classType: 'default',
                                    operation: true,
                                    sublevel: [],


                              },
                              {
                                    name: '调整粗细',
                                    img: require('../../assets/assistant/brush-initial.png'),
                                    styleType: '',
                                    classType: 'size',
                                    operation: false,
                                    sublevel: [{
                                                name: '极致细',
                                                img: require('../../assets/assistant/dot10.png'),
                                                sundry: 1,
                                                countType: false,

                                          },
                                          {
                                                name: '细',
                                                img: require('../../assets/assistant/dot15.png'),
                                                sundry: 4,
                                                countType: false,
                                          },
                                          {
                                                name: '中',
                                                img: require('../../assets/assistant/dot20.png'),
                                                sundry: 7,
                                                countType: false,
                                          },
                                          {
                                                name: '粗',
                                                img: require('../../assets/assistant/dot25.png'),
                                                sundry: 10,
                                                countType: false,
                                          },
                                          {
                                                name: '特粗',
                                                img: require('../../assets/assistant/dot30.png'),
                                                sundry: 15,
                                                countType: false,
                                          }
                                    ]
                              },
                              {
                                    name: '调整颜色',
                                    img: require('../../assets/assistant/brush-initial.png'),
                                    classType: 'color',
                                    operation: true,
                                    sublevel: [{

                                                img: require('../../assets/assistant/brush-blue.png'),
                                                sundry: '#1296db',
                                                countType: false,
                                          },
                                          {
                                                img: require('../../assets/assistant/brush-gray.png'),
                                                sundry: '#bfbfbf',
                                                countType: false,
                                          },
                                          {
                                                img: require('../../assets/assistant/brush-green.png'),
                                                sundry: '#1afa29',
                                                countType: false,
                                          },
                                          {
                                                img: require('../../assets/assistant/brush-red.png'),
                                                sundry: '#d81e06',
                                                countType: false,
                                          },
                                          {
                                                img: require('../../assets/assistant/brush-yellow.png'),
                                                sundry: '#f4ea2a',
                                                countType: false,
                                          },
                                    ],
                              },
                              {
                                    name: '笔迹撤销',
                                    img: require('../../assets/assistant/brush-initial.png'),
                                    classType: 'default',
                                    operation: true,
                                    sublevel: [],

                              },
                              {
                                    name: '橡皮檫',
                                    img: require('../../assets/assistant/eraser.png'),
                                    classType: 'eraser',
                                    operation: false,
                                    sublevel: [{
                                                img: require('../../assets/assistant/eraser20.png'),
                                                sundry: 10,
                                                countType: false,
                                          },
                                          {
                                                img: require('../../assets/assistant/eraser25.png'),
                                                sundry: 20,
                                                countType: false,
                                          },
                                          {
                                                img: require('../../assets/assistant/eraser30.png'),
                                                sundry: 30,
                                                countType: false,
                                          },
                                    ]
                              },
                              {
                                    name: '图形',
                                    img: require('../../assets/assistant/graph.png'),
                                    classType: 'graph',
                                    operation: false,

                                    sublevel: [{

                                                img: require('../../assets/assistant/wire.png'),
                                                sundry: 9,
                                                countType: true,
                                          },
                                          {
                                                img: require('../../assets/assistant/circle.png'),
                                                sundry: 10,
                                                countType: true,
                                          },
                                          {
                                                img: require('../../assets/assistant/rectangle.png'),
                                                sundry: 11,
                                                countType: true,
                                          },
                                    ],
                              },
                              {
                                    name: '清除全部',
                                    img: require('../../assets/assistant/delete.png'),
                                    classType: 'default',
                                    operation: true,
                                    sublevel: [],

                              },
                              {
                                    name: '截图上传',
                                    img: require('../../assets/assistant/screenshot.png'),
                                    classType: 'default',
                                    operation: true,
                                    sublevel: [],

                              },
                              {
                                    name: '文本',
                                    img: require('../../assets/assistant/text.png'),
                                    classType: 'text',
                                    operation: false,
                                    sublevel: [{
                                                img: require('../../assets/assistant/text15.png'),
                                                sundry: 1,
                                                countType: false,
                                          },
                                          {
                                                img: require('../../assets/assistant/text20.png'),
                                                sundry: 5,
                                                countType: false,
                                          },
                                          {
                                                img: require('../../assets/assistant/text30.png'),
                                                sundry: 8,
                                                countType: false,
                                          },
                                    ]
                              },
                              {
                                    name: '田字格',
                                    img: require('../../assets/assistant/blackboard.png'),
                                    classType: 'default',
                                    operation: true,
                                    sublevel: [],


                              },
                        ],
                        //画板工具
                        cursor: `url('${cursors.pen1}'),auto`,
                        currentImg: {
                              url: "",
                              width: "",
                              height: "",
                              scale: 1,
                              index: 0,
                        },
                        canvasStore: [],
                        prevDis: true,
                        nextDis: true,
                        // 用于绘制的画板
                        canvas_front: null,
                        // 用于生成绘制后图片的画板
                        canvas_back: null,
                        // 底图画板，橡皮擦除时获取像素放到绘制画板中，达到不擦出底图的效果
                        canvas_base: null,
                        ctx_base: null,
                        ctx_front: null,
                        ctx_back: null,
                        baseMap: "",
                        slide: 1,
                        activeTool: 1,
                        defaultColor: "#bfbfbf",
                        isExpand: 1,
                        showTools: 1,
                        canDraw: false,
                        text: "",
                        tl: 0,
                        tt: 0,
                        brushValue: 1,
                        eraserValue: 10,
                        graphValue: 1,
                        textValue: 1,
                        canvas: '',
                        context: '',
                        showType: true,
                        addType: false,

                        //弹窗
                        secondValue: '',
                        minuteValue: '',
                        centerDialogVisible: false,
                        switchStatus: false,
                        iconType: false,
                        visible: false,
                        // hint: false,
                        time: '',
                        timeType: '',
                        tooltip: false,

                  }
            },
            //方法
            methods: {
                  //返回
                  goback() {
                        this.$router.go(-1);
                  },
                  //全屏切换
                  screen() {
                        let element = document.documentElement;
                        if (this.fullscreen) {
                              if (document.exitFullscreen) {
                                    document.exitFullscreen();
                              } else if (document.webkitCancelFullScreen) {
                                    document.webkitCancelFullScreen();
                              } else if (document.mozCancelFullScreen) {
                                    document.mozCancelFullScreen();
                              } else if (document.msExitFullscreen) {
                                    document.msExitFullscreen();
                              }
                        } else {
                              if (element.requestFullscreen) {
                                    element.requestFullscreen();
                              } else if (element.webkitRequestFullScreen) {
                                    element.webkitRequestFullScreen();
                              } else if (element.mozRequestFullScreen) {
                                    element.mozRequestFullScreen();
                              } else if (element.msRequestFullscreen) {
                                    // IE11
                                    element.msRequestFullscreen();
                              }
                        }
                        this.fullscreen = !this.fullscreen;
                  },
                  itemSonck(name, value) {
                        if (name == '调整粗细') {
                              this.slide = value;
                              this.brushValue = value;
                              console.log('我的位置', this.brushValue)
                        } else if (name == '调整颜色') {
                              this.defaultColor = value;
                        } else if (name == '橡皮檫') {
                              this.slide = value;
                              this.eraserValue = value;
                        } else if (name == '文本') {
                              this.slide = value;
                              this.textValue = value;
                        } else if (name == '图形') {
                              this.slide = 1;
                              if (value == 9) {
                                    console.log('线')
                                    this.handleChangeToolType(9)
                              } else if (value == 10) {
                                    this.handleChangeToolType(10)
                                    console.log('圆')
                              } else {
                                    this.handleChangeToolType(11)
                                    console.log('矩形')
                              }
                        } else if (name == '田字格') {

                        }
                        this.MaskIndex = '';

                  },
                  handleChange() {

                  },
                  // 生成画板底图
                  add1() {
                        this.showType = true;
                        this.addType = false;
                        this.drawerstate = true;
                        // this.screen();
                        this.$nextTick(() => {
                              this.scImg();
                        })

                  },
                  scImg() {
                        this.addType = true;
                        let div = this.showType ? this.$refs.view1 : this.$refs.view2;
                        console.log('div', div)
                        html2canvas(div).then((canvas) => {
                              let dataURL = canvas.toDataURL("image/png");
                              this.dom = dataURL;
                              this.baseMap = dataURL;
                              this.handleInitCanvas(dataURL);
                              this.handleChangeToolType();

                        })
                  },
                  switchover(index) {
                        this.MaskIndex = index;
                        clearTimeout(this.time1);
                        this.time1 = setTimeout(() => {
                              this.MaskIndex = '';
                        }, 3000)
                        switch (index) {

                              case 0:
                                    //关闭
                                    this.drawerstate = false;
                                    this.addType = false;
                                    this.showType = true;
                                    break;
                              case 2:
                                    //颜色
                                    break;
                              case 3:
                                    //撤销
                                    this.handlePrev();
                                    break;
                              case 6:
                                    //清除全部
                                    this.handleClearCanvas();
                                    break;
                              case 7:
                                    // 截图上传
                                    this.handleCanvas2Img();
                                    break;
                              case 9:
                                    //田字格
                                    this.showType = !this.showType;
                                    this.addType = false;

                                    this.$nextTick(() => {
                                          this.scImg();
                                    })

                                    break;
                        }
                  },
                  /** 初始化画布*/
                  handleInitCanvas(aurl) {
                        this.currentImg = {
                              url: aurl,
                              width: "",
                              height: "",
                              scale: 1,
                              index: 0,
                        };
                        this.canvasStore = [aurl];
                        this.prevDis = true;
                        this.nextDis = true;
                        // // 用于绘制的画板
                        this.canvas_front = document.getElementById("ctx_front");
                        // 用于生成绘制后图片的画板
                        this.canvas_back = document.getElementById("ctx_back");
                        // this.canvas_back.style.border = '1px solid red'
                        // 底图画板，橡皮擦除时获取像素放到绘制画板中，达到不擦出底图的效果
                        this.canvas_base = document.getElementById("ctx_base");
                        //获取

                        this.ctx_base = this.canvas_base.getContext("2d");
                        this.ctx_front = this.canvas_front.getContext("2d");
                        this.ctx_back = this.canvas_back.getContext("2d");
                        this.ctx_front.strokeStyle = this.defaultColor;
                        let img = new Image();
                        img.crossOrigin = "";
                        img.src = this.baseMap;
                        let _this = this;

                        img.onload = function () {
                              let width = parseInt(this.width);
                              let height = parseInt(this.height);
                              _this.currentImg.width = width;
                              _this.currentImg.height = height;
                              _this.canvas_front.width = width;
                              _this.canvas_front.height = height;
                              _this.canvas_back.width = width;
                              _this.canvas_back.height = height;
                              _this.canvas_base.width = width;
                              _this.canvas_base.height = height;
                              _this.ctx_front.drawImage(this, 0, 0, width, height);
                              _this.ctx_back.drawImage(this, 0, 0, width, height);
                              _this.ctx_base.drawImage(this, 0, 0, width, height);
                        };
                  },
                  /** 工具切换*/
                  handleChangeToolType(type) {
                        clearTimeout(this.time1);
                        this.time1 = setTimeout(() => {
                              this.MaskIndex = '';
                        }, 3000)
                        if (this.MaskIndex == '') {
                              this.MaskIndex = type;
                        } else if (type == this.MaskIndex) {
                              this.MaskIndex = '';
                        } else {
                              this.MaskIndex = type;
                        }
                        this.activeTool = type;
                        switch (type) {
                              case 1:
                                    //画笔
                                    this.cursor = `url('${cursors.pen1}'),auto`;
                                    this.slide = this.brushValue;
                                    break;
                              case 4:
                                    //橡皮檫
                                    this.slide = this.eraserValue;
                                    this.cursor = `url('${cursors.eraser}'),auto`;
                                    break;
                              case 5:
                                    //图形
                                    this.slide = this.graphValue;
                                    break;
                              case 8:
                                    // 文字
                                    this.slide = this.textValue;
                                    this.cursor = `url('${cursors.text}'),auto`;
                                    break;
                              case 9:
                                    // 线
                                    this.slide = this.graphValue;
                                    this.cursor = `crosshair`;
                                    break;
                              case 10:
                                    // 圆
                                    this.slide = this.graphValue;
                                    this.cursor = `crosshair`;
                                    break;
                              case 11:
                                    // 矩形
                                    this.slide = this.graphValue;
                                    this.cursor = `crosshair`;
                                    break;
                              default:
                                    this.cursor = `url('${cursors.pen1}'),auto`;
                                    this.slide = this.brushValue;
                                    break;
                        }
                        type ? this.handleDrawCanvas(type) : this.handleDrawCanvas(1);

                  },

                  handleDrawCanvas(type) {
                        this.canDraw = false;
                        let sx, sy, mx, my;
                        let text = document.getElementById("text");
                        //鼠标按下
                        let mousedown = (e) => {
                              this.ctx_front.strokeStyle = this.defaultColor;
                              this.ctx_front.lineWidth = this.slide;
                              e = e || window.event;
                              sx = e.clientX - this.canvas_front.offsetLeft;
                              console.log('e.clientY', e.clientY)
                              console.log('this.canvas_front.offsetTop', this.canvas_front.offsetTop)
                              sy = e.clientY - this.canvas_front.offsetTop;

                              const cbx = this.ctx_base.getImageData(
                                    e.offsetX - this.slide / 2,
                                    e.offsetY - this.slide / 2,
                                    this.slide * 2,
                                    this.slide * 2
                              );
                              this.ctx_front.moveTo(sx, sy);
                              this.canDraw = true;
                              switch (type) {
                                    case 1:
                                          //画笔
                                          this.ctx_front.beginPath();
                                          break;
                                    case 4:
                                          //橡皮檫
                                          this.ctx_front.putImageData(
                                                cbx,
                                                e.offsetX - this.slide / 2,
                                                e.offsetY - this.slide / 2
                                          );
                                          break;
                                    case 8:
                                          // 文字
                                          this.handleTextBlur();
                                          this.text = "";
                                          text.style.fontSize = 14 + this.slide * 10 + "px";
                                          text.style.color = this.defaultColor;
                                          text.style.left =
                                                e.offsetX + this.canvas_front.offsetLeft - 20 + "px";
                                          text.style.top =
                                                e.offsetY + this.canvas_front.offsetTop - 10 + "px";
                                          text.style.zIndex = 10;
                                          text.style.display = "block";
                                          this.tl = e.offsetX - 20;
                                          this.tt = e.offsetY + 10;
                                          break;
                              }
                        };
                        let mousemove = (e) => {
                              e = e || window.event;
                              mx = e.clientX - this.canvas_front.offsetLeft;
                              my = e.clientY - this.canvas_front.offsetTop;
                              const cbx = this.ctx_base.getImageData(
                                    e.offsetX - this.slide / 2,
                                    e.offsetY - this.slide / 2,
                                    this.slide * 2,
                                    this.slide * 2
                              );
                              if (this.canDraw) {
                                    switch (type) {
                                          case 1:
                                                //画笔
                                                // this.ctx_front.lineTo(mx - 8, my + 4);
                                                this.ctx_front.lineTo(mx - 78, my + 4);
                                                this.ctx_front.stroke();
                                                break;
                                          case 9:
                                                this.handleFrommatCanvas();
                                                this.ctx_front.beginPath();
                                                // this.ctx_front.moveTo(sx - 10, sy - 79);
                                                // this.ctx_front.lineTo(mx - 10, my - 79);
                                                this.ctx_front.moveTo(sx - 85, sy - 5);
                                                this.ctx_front.lineTo(mx - 85, my - 5);
                                                this.ctx_front.stroke();
                                                break;
                                          case 10:
                                                this.handleFrommatCanvas();
                                                this.ctx_front.beginPath();
                                                // eslint-disable-next-line no-case-declarations
                                                let rds = Math.sqrt(
                                                      // (sx - 10 - mx) * (sx - 10 - mx) +
                                                      // (sy - 49 - my) * (sy - 49 - my)
                                                      (sx - 10 - mx) * (sx - 10 - mx) +
                                                      (sy - 49 - my) * (sy - 49 - my)
                                                );
                                                // this.ctx_front.arc(sx - 15, sy - 69, rds, 0, Math.PI * 2, false);
                                                this.ctx_front.arc(sx - 75, sy + 10, rds, 0, Math.PI * 2, false);
                                                this.ctx_front.stroke();
                                                break;
                                          case 4:
                                                //橡皮檫
                                                this.ctx_front.putImageData(
                                                      cbx,
                                                      e.offsetX - this.slide / 2,
                                                      e.offsetY - this.slide / 2
                                                );
                                                break;
                                          case 11:
                                                this.handleFrommatCanvas();
                                                this.ctx_front.beginPath();
                                                this.ctx_front.moveTo(sx - 100, sy - 5);
                                                this.ctx_front.lineTo(mx - 100, sy - 5);
                                                this.ctx_front.lineTo(mx - 100, my - 5);
                                                this.ctx_front.lineTo(sx - 100, my - 5);
                                                this.ctx_front.lineTo(sx - 100, sy - 5);
                                                this.ctx_front.stroke();
                                                break;

                                    }
                              }
                        };
                        let mouseup = () => {
                              if (this.canDraw) {
                                    this.canDraw = false;
                                    this.ctx_front.closePath();
                                    if (type != 8) {
                                          this.handleSaveCanvasStore();
                                    }
                              }
                        };
                        this.canvas_front.onmousedown = (e) => mousedown(e);
                        this.canvas_front.onmousemove = (e) => mousemove(e);
                        this.canvas_front.onmouseup = (e) => mouseup(e);
                        this.canvas_front.onmouseout = (e) => mouseup(e);
                        this.canvas_front.onmouseleave = (e) => mouseup(e);
                  },
                  //--------------
                  /** 显示或隐藏设置栏*/
                  handleShowOrHide(status) {
                        this.isExpand = status;
                  },
                  /** 显示或隐藏工具栏*/
                  handleShowTools(status) {
                        this.showTools = status;
                  },
                  handleShowCanvas() {
                        this.show = true;
                  },
                  /** 处理放大缩小*/
                  handleDrawImage() {
                        let _this = this;
                        let img = new Image();
                        let baseImg = new Image();
                        img.src = this.currentImg.url;
                        baseImg.src = this.baseMap;
                        _this.currentImg.width = _this.currentImg.width * this.currentImg.scale;
                        _this.currentImg.height = _this.currentImg.height * this.currentImg.scale;
                        img.onload = function () {
                              _this.canvas_front.width = _this.currentImg.width;
                              _this.canvas_front.height = _this.currentImg.height;
                              _this.canvas_back.width = _this.currentImg.width;
                              _this.canvas_back.height = _this.currentImg.height;
                              _this.ctx_front.drawImage(
                                    this,
                                    0,
                                    0,
                                    _this.currentImg.width,
                                    _this.currentImg.height
                              );
                              _this.ctx_back.drawImage(
                                    this,
                                    0,
                                    0,
                                    _this.currentImg.width,
                                    _this.currentImg.height
                              );
                        };
                        baseImg.onload = () => {
                              _this.canvas_base.width = _this.currentImg.width;
                              _this.canvas_base.height = _this.currentImg.height;
                              _this.ctx_base.drawImage(
                                    baseImg,
                                    0,
                                    0,
                                    _this.currentImg.width,
                                    _this.currentImg.height
                              );
                        };
                  },
                  handleBeLarge() {
                        this.currentImg.scale = 1;
                        this.currentImg.scale += 0.1;
                        this.$nextTick(() => {
                              this.handleDrawImage();
                        });
                  },
                  handleBeSmall() {
                        this.currentImg.scale = 1;
                        this.currentImg.scale -= 0.1;
                        this.$nextTick(() => {
                              this.handleDrawImage();
                        });
                  },
                  /** 下载图片*/
                  handleCanvas2Img() {
                        let canvas = document.getElementById("ctx_back");
                        this.$refs.download.href = canvas.toDataURL();
                        this.$refs.download.click();
                  },
                  /** 清除画布*/
                  handleClearCanvas() {
                        this.handleInitCanvas();
                  },
                  handleFrommatCanvas() {
                        this.ctx_front.clearRect(
                              0,
                              0,
                              this.canvas_front.width,
                              this.canvas_front.height
                        );
                  },
                  /** 失焦或者回车绘制文本，框隐藏*/
                  handleTextBlur() {
                        let text = document.getElementById("text");
                        this.ctx_front.font = `300 ${text.style.fontSize} sans-serif`;
                        this.ctx_front.fillStyle = this.defaultColor;
                        this.ctx_front.fillText(this.text, this.tl, this.tt);
                        text.style.display = "none";
                        this.text = "";
                        text.value = ''
                        this.handleSaveCanvasStore();
                  },
                  /** 上一步*/
                  handlePrev() {
                        console.log('this.currentImg.index', this.currentImg.index)
                        if (this.currentImg.index > 0) {
                              this.nextDis = false;
                              this.currentImg.index -= 1;
                              this.currentImg.index == 0 ? this.prevDis = true : this.prevDis = false
                              this.currentImg.url = this.canvasStore[this.currentImg.index];
                              this.currentImg.scale = 1;
                              this.handleDrawImage();
                        } else {
                              this.prevDis = true;
                              this.handleClearCanvas();
                        }
                  },
                  /** 下一步*/
                  handleNext() {
                        if (this.currentImg.index < this.canvasStore.length - 1) {
                              this.prevDis = false;
                              this.currentImg.index += 1;
                              this.currentImg.index == this.canvasStore.length - 1 ? this.nextDis = true : this
                                    .nextDis = false
                              this.currentImg.url = this.canvasStore[this.currentImg.index];
                              this.currentImg.scale = 1;
                              this.handleDrawImage();
                        } else {
                              this.nextDis = true;
                        }
                  },
                  /** 保存绘制*/
                  handleSaveCanvasStore() {
                        let url = this.canvas_front.toDataURL();
                        let image = new Image();
                        image.src = url;
                        image.onload = () => {

                              this.ctx_front.clearRect(
                                    0,
                                    0,
                                    this.canvas_front.width,
                                    this.canvas_front.height
                              );
                              this.ctx_front.drawImage(image, 0, 0, image.width, image.height);
                              this.ctx_back.drawImage(image, 0, 0, image.width, image.height);
                              const url2 = this.canvas_back.toDataURL();
                              this.currentImg.url = url2;
                              this.currentImg.index += 1;
                              this.canvasStore.push(url2);
                              this.prevDis = false;
                        };
                  },
                  //绘制田字格
                  draw() {
                        let dom = document.getElementsByClassName("cs");
                        for (let i = 0; i < dom.length; i++) {
                              this.drawGrid(dom[i]);
                        }
                  },
                  drawGrid(valve) {
                        this.canvas = valve;
                        this.context = this.canvas.getContext('2d');
                        this.canvas.height = this.canvas.width;
                        this.context.save();
                        this.context.strokeStyle = "rgb(203,195,129)";
                        this.context.beginPath();
                        this.context.moveTo(3, 3);
                        this.context.lineTo(this.canvas.width - 3, 3);
                        this.context.lineTo(this.canvas.width - 3, this.canvas.height - 3);
                        this.context.lineTo(3, this.canvas.height - 3);
                        this.context.closePath();

                        this.context.lineWidth = 6;
                        this.context.stroke();

                        this.context.beginPath();
                        this.context.moveTo(0, 0);
                        this.context.lineTo(this.canvas.width, this.canvas.height);

                        this.context.moveTo(this.canvas.width, 0);
                        this.context.lineTo(0, this.canvas.height);

                        this.context.moveTo(this.canvas.width / 2, 0);
                        this.context.lineTo(this.canvas.width / 2, this.canvas.height);

                        this.context.moveTo(0, this.canvas.width / 2);
                        this.context.lineTo(this.canvas.width, this.canvas.height / 2);
                        this.context.lineWidth = 1;
                        this.context.stroke();
                        this.context.restore();
                  },

                  //计时器
                  switchChange() {
                        this.iconType = false;
                        this.switchStatus ? this.$refs.Widget.setTimer() : this.$refs.Widget.setTimer2()
                  },
                  transmit(value, type) {

                        this.time = value;
                        this.timeType = type;
                        this.$refs.Widget.time1(this.time, this.timeType);
                        this.iconType = false;
                        console.log('当前位置', this.iconType)


                  },
                  startTime() {
                        if (this.time != '') {
                              this.iconType = !this.iconType;
                              this.iconType ? this.$refs.Widget.statr() : this.$refs.Widget.suspend();
                              this.tooltip = false;
                        } else {
                              alert('请选择时间')
                              // this.tooltip = true;
                        }
                  },
                  secondFocus() {
                        this.minuteValue = '';
                  },
                  minuteFocus() {
                        this.secondValue = '';
                  },
                  customization() {
                        if (this.minuteValue != '') {
                              this.transmit(this.minuteValue, 'm')
                        }
                        if (this.secondValue != '') {
                              this.transmit(this.secondValue, 's')
                        }
                        this.visible = false;
                  },
                  parentFn(value) {
                        this.iconType = value
                  }

            },
            //生命周期 - 创建完成（访问当前this实例）
            created() {

            },
            //生命周期 - 挂载完成（访问DOM元素）
            mounted() {
                  this.draw();
            }
      }
</script>
<style lang='less' scoped>
      @import './less/painter';

      #text {
            position: absolute;
            z-index: -1;
            resize: none;
            outline: none;
            border: 1px dashed gray;
            overflow: hidden;
            background: transparent;
            line-height: 30px;
            display: none;
            max-width: 800px;
      }
</style>